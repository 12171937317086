export const MySQLKeywords: string[] = [
  'ADD',
  'ALTER',
  'AND',
  'AS',
  'ASC',
  'AUTO_INCREMENT',
  'BY',
  'CASCADE',
  'COLUMN',
  'COMMENT',
  'CONSTRAINT',
  'CREATE',
  'DATABASE',
  'DEFAULT',
  'DELETE',
  'DESC',
  'DROP',
  'EXISTS',
  'FOREIGN',
  'IF',
  'INDEX',
  'KEY',
  'LIKE',
  'NOT',
  'NULL',
  'ON',
  'OR',
  'PRIMARY',
  'REFERENCES',
  'RENAME',
  'SCHEMA',
  'SELECT',
  'SET',
  'TABLE',
  'UNION',
  'UNIQUE',
  'USE',
];
