import './App.less';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { Col, Layout, Row, Select } from 'antd';
import { HeaderSection } from './components/HeaderSection';
import { useDispatch } from 'react-redux';
import { fetchOptionThunkAction } from './redux/factoryStateReducer';
import { Adsense } from '@ctrl/react-adsense';
import { ContentSection } from './components/ContentSection';


const { Option, OptGroup } = Select;

const { Header, Footer, Sider, Content } = Layout;


// export const LAYOUT_CONSTANTS = {"HEADER_HEIGHT": "60px", "B": 16};
export const COL_AB_SPAN = { 'A': 10, 'B': 14, 'B1': 10, 'B2': 14 };
export const ColorPalette = {
  flex: '1 1 auto',
  backgroundColor: '#FFFFFF',
  primaryColor: '#673ab7',
  primaryLightColor: '#9a67ea',
  primaryDarkColor: '#320b86',

  secondaryColor: '#a5d6a7',
  secondaryLightColor: '#d7ffd9',
  secondaryDarkColor: '#75a478',

  textOnPrimaryColor: '#ffffff',
  textOnSecondaryColor: '#000000',
  // border: "1px solid grey",
  height: '83vh',
  border: '1px solid rgba(0,0,0,0.2)',
  popupBgColor: '#f5f8fa',

  // width: "40%"
};

export interface DdlLayoutProps {
  v_height: string;
}

const App: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let result = dispatch(fetchOptionThunkAction(1));
    console.log(result);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: ColorPalette.primaryColor }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HeaderSection v_height={'46px'} />
          <ContentSection v_height={'86vh'} />
        </Col>
      </Row>
      <Row>
      </Row>


      // ads with custom format
      // responsive and native ads
      {/*
        <Adsense
          className={'adsbygoogle'}
          client='ca-pub-5125314041569973'
          slot='4329082157'
          style={{ display: 'block' }}
          format='auto'
        />
*/}

    </div>
  );
};

export default App;