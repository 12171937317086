import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DefaultSQL } from './constants';
import { CreateTable } from './ddl/sqlParser/types';
import Handlebars from 'handlebars';
import { DDLParser } from './ddl/SQLParser';
import { Archetype, Artifact } from './factoryStateReducer';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

// private String templateKey;
// private String convertddl.proto;
// private Map<String, Object> options;

// class ConvertCmd {
//     archetypeId: number;
//     archetype: Archetype;
//     convertddl.proto: string;
//     options: OptionMap
// }
//
class SqlError {
  line: number;
  column: number;
  sql: string;
  message: string;
}

export interface ConvertResult {
  artifacts: Artifact[];
  errMsg?: string;
  sqlError?: SqlError;
  result?: string;
}

export interface ConvertState {
  archetype: Archetype,
  ddl: string;
  options: OptionMap;
  // result
  artifacts: Artifact[];
  result: string;
  sqlError: SqlError;
  errMsg: string;
}

export type OptionMap = {
  [key: string]: OptionVal
}

export type OptionVal = boolean | string;

export type OptionPair = {
  key: string,
  value: OptionVal
};

export const setDdlAction = createAction<string>('setDdlAction');
export const setArchetype = createAction<Archetype>('setArchetype');
export const setStrOption = createAction<OptionPair>('setStrOption');
export const setBoolOption = createAction<OptionPair>('setBoolOption');
export const copyToClipboardAction = createAction<void>('copyToClipboard');
export const downloadArtifactsAction = createAction<void>('downloadArtifacts');


export const convertDdlThunkAction = createAsyncThunk<ConvertResult, ConvertState>(
  'convertDdl',
  async (cmd, thunkApi) => {
    const ddlStatList = DDLParser(cmd.ddl) as CreateTable[];
    const ddlStat = ddlStatList[0];
    // for (let i = 0; i < ddlStatList.length; i++) {
    //     let ddlStat = ddlStatList[i];
    // }

    console.log('parsed', ddlStat);
    const archetype = cmd.archetype;
    console.log('find archetype', archetype);

    let artifacts: Artifact[] = [];
    for (let i = 0; i < archetype.templates.length; i++) {
      let tmpl = archetype.templates[i];
      let compiledHeaderTmpl = Handlebars.compile(tmpl.header);
      let compiledBodyTmpl = Handlebars.compile(tmpl.body);

      let contextData = { data: ddlStat, config: cmd.options };
      let header = compiledHeaderTmpl(contextData);
      let body = compiledBodyTmpl(contextData);
      let artifact = { header: header, body: body };
      artifacts.push(artifact);
      console.log('built ' + artifact);
    }

    let resultStr = artifacts.flatMap(v => [v.header, v.body]).join('\n--------------------------------\n');
    console.log(resultStr);
    let convertResult: ConvertResult = { errMsg: '', sqlError: null, result: resultStr, artifacts: artifacts };
    return convertResult;
  });

const initialState: ConvertState = {
  archetype: { id: 0, desc: '', options: [], templates: [], lang: 'text' },
  ddl: DefaultSQL,
  options: {},
  result: '',
  sqlError: null,
  errMsg: null,
  artifacts: [],
};
const convertDdlSliceReducer = createSlice({
  name: 'convertDdl',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setArchetype, (state, { payload }) => {
      console.log('reduce ', payload);
      state.archetype = payload;
      state.options = {};
    });

    builder.addCase(copyToClipboardAction, (state, { payload }) => {
      navigator.clipboard.writeText(state.result);
    });

    builder.addCase(downloadArtifactsAction, (state, { payload }) => {
      if (state.artifacts.length === 1) {
        let artifact = state.artifacts[0];
        let blob = new Blob([artifact.body], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, artifact.header);
      } else {
        let zip = new JSZip();
        state.artifacts.forEach(a => {
          zip.file(a.header, a.body);
        });
        zip.generateAsync({ type: 'blob' })
          .then(function(content) {
            FileSaver.saveAs(content, 'output.zip');
          });
      }
    });

    builder.addCase(setDdlAction, (state, { payload }) => {
      state.ddl = payload;
      state.sqlError = null;
      state.errMsg = null;
    });

    builder.addCase(setStrOption, (state, { payload }) => {
      state.options[payload.key] = payload.value;
    });

    builder.addCase(setBoolOption, (state, { payload }) => {
      state.options[payload.key] = payload.value;
    });

    builder.addCase(convertDdlThunkAction.fulfilled, (state, { payload }) => {
      console.log('change state', payload);
      state.result = payload.result;
      state.sqlError = payload.sqlError;
      state.errMsg = payload.errMsg;
      state.artifacts = payload.artifacts;
    });

    builder.addCase(convertDdlThunkAction.rejected, (state, { payload }) => {
      console.log(payload);
    });

  },
});


export default convertDdlSliceReducer.reducer;

/*
export const convertDdlThunkAction = createAsyncThunk<ConvertResult, ConvertCmd>(
    'convertDdl',
    async (cmd, thunkApi) => {
        const response = await fetch(CONVERT_URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cmd)
        })
        return (await response.json()) as (ConvertResult);
    })
*/

