import {combineReducers, Reducer} from "redux";
import ArtifactFactorySliceReducer, {ArtifactFactory} from "./factoryStateReducer";
import ConvertStateReducer, {ConvertState} from "./sourceStateReducer";


export type RootState = {
    factory: ArtifactFactory;
    convertState: ConvertState;
}

export const rootReducer: Reducer<RootState> = combineReducers({
    factory: ArtifactFactorySliceReducer,
    convertState: ConvertStateReducer
});
