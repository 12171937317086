import {configureStore} from "@reduxjs/toolkit";
import {rootReducer} from "./rootReducer";
import {logger} from 'redux-logger'


import {useDispatch} from "react-redux";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            // .prepend(
            // correctly typed middlewares can just be used
            // additionalMiddleware,
            // you can also type middlewares manually
            // untypedMiddleware as Middleware<(action: Action<'specialAction'>) => number,
            //     RootState>
            // )
            // prepend and concat calls can be chained
            .concat(logger),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store;