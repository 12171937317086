/**
 * https://www.postgresql.org/docs/current/datatype.html
 */
export const PostgreSQLTypes: string[] = [
  'BIGINT',
  'BIGSERIAL',
  'BIT VARYING',
  'BIT',
  'BOOL',
  'BOOLEAN',
  'BOX',
  'BYTEA',
  'CHAR',
  'CHARACTER VARYING',
  'CHARACTER',
  'CIDR',
  'CIRCLE',
  'DATE',
  'DECIMAL',
  'DOUBLE PRECISION',
  'FLOAT4',
  'FLOAT8',
  'INET',
  'INT',
  'INT2',
  'INT4',
  'INT8',
  'INTEGER',
  'INTERVAL',
  'JSON',
  'JSONB',
  'LINE',
  'LSEG',
  'MACADDR',
  'MACADDR8',
  'MONEY',
  'NUMERIC',
  'PATH',
  'PG_LSN',
  'POINT',
  'POLYGON',
  'REAL',
  'SERIAL',
  'SERIAL2',
  'SERIAL4',
  'SERIAL8',
  'SMALLINT',
  'SMALLSERIAL',
  'TEXT',
  'TIME WITH',
  'TIME',
  'TIMESTAMP WITH',
  'TIMESTAMP',
  'TIMESTAMPTZ',
  'TIMETZ',
  'TSQUERY',
  'TSVECTOR',
  'TXID_SNAPSHOT',
  'UUID',
  'VARBIT',
  'VARCHAR',
  'XML',
];
