/**
 * https://docs.microsoft.com/ko-kr/sql/t-sql/data-types/data-types-transact-sql?view=sql-server-ver15
 */
export const MSSQLTypes: string[] = [
  'BIGINT',
  'BINARY',
  'BIT',
  'CHAR',
  'DATE',
  'DATETIME',
  'DATETIME2',
  'DATETIMEOFFSET',
  'DECIMAL',
  'FLOAT',
  'GEOGRAPHY',
  'GEOMETRY',
  'IMAGE',
  'INT',
  'MONEY',
  'NCHAR',
  'NTEXT',
  'NUMERIC',
  'NVARCHAR',
  'REAL',
  'SMALLDATETIME',
  'SMALLINT',
  'SMALLMONEY',
  'SQL_VARIANT',
  'TEXT',
  'TIME',
  'TINYINT',
  'UNIQUEIDENTIFIER',
  'VARBINARY',
  'VARCHAR',
  'XML',
];
