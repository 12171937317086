import { default as React, useEffect, useState } from 'react';
// import {NavBar} from 'app/components/NavBar';
// import {NavBar} from 'app/components/NavBar';
import { Button, Checkbox, Col, Form, Input, Popover, Radio, Row, Select, Space, Switch } from 'antd';
import { COL_AB_SPAN, ColorPalette, DdlLayoutProps } from '../App';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
  convertDdlThunkAction,
  copyToClipboardAction,
  downloadArtifactsAction,
  setArchetype,
  setBoolOption,
  setStrOption,
} from '../redux/sourceStateReducer';

export const HeaderSection: React.FC<DdlLayoutProps> = ({ v_height }: DdlLayoutProps) => {
// export function HeaderSection() {
  const [visible, setVisible] = useState(true);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.factory);
  console.log('see', data);
  const convertState = useSelector((state: RootState) => state.convertState);
  const archetype = convertState.archetype;

  let archetypeSelectOptions = data.archetypes.map(t => {
    return <>
      <Select.Option value={t.id}>{t.desc}</Select.Option>
    </>;
  });

  const OPTION_SWITCH =
    visible ?
      <>
        <EyeInvisibleOutlined style={{ fontSize: '28px' }} onClick={() => setVisible(false)} />
      </> : <>
        <EyeOutlined style={{ fontSize: '28px' }} onClick={() => setVisible(true)} />
      </>;


  function selectArchetype(id) {
    return data.archetypes.find(v => v.id === id);
  }

  useEffect(() => {
    if (data.archetypes.length > 0) {
      dispatch(setArchetype(selectArchetype(0)));
    }
  }, [data.archetypes]);


  if (data === undefined || data.archetypes === undefined || data.archetypes.length === 0) {
    return (<div>text</div>);
  }

  return (
    <>
      <Row style={{
        height: v_height,
        // width: '100%',
        backgroundColor: ColorPalette.primaryColor,
        // verticalAlign: 'center',
        // padding: '8px 8px 8px 8px',
        color: ColorPalette.textOnPrimaryColor,
      }}>
        <Col span={1} />
        <Col span={22}>
          <Col span={COL_AB_SPAN.A} id='logo_slogan'>
            <a href='#' style={{
              color: ColorPalette.textOnPrimaryColor,
              // fontStyle: "bold",
            }}>
                        <span style={{
                          color: ColorPalette.textOnPrimaryColor,
                          paddingLeft: '20px',
                          fontSize: '26px',
                          lineHeight: '0px',
                        }}>convertDDL.com</span>
              <span style={{
                fontSize: '28px',
                lineHeight: '0px',
              }}>
                            &nbsp;
                &nbsp;
                            </span>
            </a>

            <span style={{
              fontSize: '18px',
              fontStyle: 'bold',
              color: ColorPalette.textOnPrimaryColor,
            }}>
                        convert DDL to anything you want
                    </span>
          </Col>

          <Col span={COL_AB_SPAN.B} id='action_config'>
            <Row>
              <Col pull={0} id={'action_choice'}>
                            <span style={{
                              color: ColorPalette.textOnPrimaryColor,
                              fontStyle: 'bold',
                              fontSize: '1.4em',
                              display: 'inline-flex',
                              verticalAlign: 'center',
                              lineHeight: '0px',
                            }}> DDL Convert To&nbsp; </span>
                <Select
                  allowClear={false}
                  showSearch={true}
                  style={{ minWidth: '180px' }}
                  placeholder={<span
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}>*</span>}
                  dropdownStyle={{ zIndex: 1000000000 }}
                  optionFilterProp='children'
                  onChange={e => {
                    let archetype = selectArchetype(e);
                    dispatch(
                      setArchetype(archetype),
                    );
                  }}
                  defaultValue={archetype.id}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {archetypeSelectOptions}
                </Select>

                <Button type={'primary'}
                        style={{ width: '80px' }}
                        onClick={() => dispatch(convertDdlThunkAction(convertState))}> GO</Button>
              </Col>
              <Col pull={0}>
                <div>
                  OPTIONS &nbsp; {OPTION_SWITCH}
                </div>
                <Popover
                  title='Options'
                  trigger='click'
                  // style={{ marginTop: '60px' }}
                  placement={'bottomLeft'}
                  arrowPointAtCenter={false}
                  visible={visible}
                  // onVisibleChange={this.handleVisibleChange}
                  content={
                    // <div style={{ minHeight: '300px', minWidth: '280px' }}>
                    <div style={{ minHeight: '300px' }}>
                      <Form
                        labelAlign={'right'}
                        layout='horizontal'
                        labelCol={{ span: 8 }}
                        colon={true}
                      >
                        {
                          archetype.options.map(o => {
                            switch (o.type) {
                              case 'INPUT':
                                return (
                                  <Form.Item label={o.desc} key={o.key}>
                                    <Input id={o.key.toString()}
                                           onChange={e => dispatch(
                                             setStrOption({
                                               key: o.key,
                                               value: e.target.value,
                                             }),
                                           )}

                                    />
                                  </Form.Item>
                                );
                              case 'BOOL':
                                return (
                                  <Form.Item name='switch' label={o.desc} valuePropName='checked'>
                                    <Switch onChange={e => dispatch(setBoolOption({ key: o.key, value: e }))} />
                                  </Form.Item>
                                );
                              case 'RADIO':
                                return (
                                  <Form.Item label={o.desc} key={o.key}>
                                    <Radio.Group defaultValue={o.defaultVal}
                                                 onChange={e => dispatch(
                                                   setStrOption({
                                                     key: o.key,
                                                     value: e.target.value,
                                                   }))}
                                    >
                                      {
                                        o.values.map((v, i) => {
                                          return <Radio value={v.value}
                                                        key={o.key + '_' + v.value}>
                                            {v.desc}
                                          </Radio>;
                                        })
                                      }
                                    </Radio.Group>
                                  </Form.Item>
                                );
                              case 'SELECT':
                                return (
                                  <Form.Item label={o.desc} key={o.key}>
                                    <Select defaultValue={o.defaultVal}
                                            onChange={e => dispatch(
                                              setStrOption({
                                                key: o.key,
                                                value: e,
                                              }))}
                                            style={{ zIndex: 10000000000000 }}>
                                      {
                                        o.values.map((ri, i) => {
                                          return <Select.Option value={ri.value}
                                                                key={ri.value}>
                                            {ri.desc}
                                          </Select.Option>;
                                        })
                                      }
                                    </Select>
                                  </Form.Item>
                                );
                            }
                          })
                        }
                        <Form.Item style={{ textAlign: 'center' }} wrapperCol={{ span: 24, offset: 2 }}>
                          <div style={{ height: '8px' }}></div>
                          <Button type='primary'
                                  style={{ width: '120px' }}
                                  onClick={() => {
                                    dispatch(copyToClipboardAction());
                                  }}
                          >Copy</Button>
                          <span>&nbsp;</span>
                          <Button type='primary'
                                  style={{ width: '120px' }}
                                  onClick={() => {
                                    dispatch(downloadArtifactsAction());
                                  }}>Download</Button>
                        </Form.Item>
                      </Form>
                    </div>
                  } />
                {/*</div>*/}
              </Col>
            </Row>
          </Col>
        </Col>

        <Col span={1} />
      </Row>
    </>);
};

