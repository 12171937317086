import { Col, Row } from 'antd';
import * as React from 'react';
import { COL_AB_SPAN, DdlLayoutProps } from '../App';
import MonacoEditor from '@monaco-editor/react';
import Editor from '@monaco-editor/react';
import { setDdlAction } from '../redux/sourceStateReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';


export const ContentSection: React.FC<DdlLayoutProps> = ({ v_height }: DdlLayoutProps) => {

  const dispatch = useDispatch();
  const convertState = useSelector((state: RootState) => state.convertState);
  // let editorHeight = '80vh'; // By default, it fully fits with its parent
  let editorHeight = v_height; // By default, it fully fits with its parent


  return (
    <>
      <Row id='ab'>
        <Col span={1}></Col>
        <Col span={22}>
          {/*<Row gutter={[1, 1]} justify={'space-around'}>*/}
          <Row gutter={[6, 6]}>
            <Col span={COL_AB_SPAN.A}>
              <div id='editor'>
                <MonacoEditor
                  // path={idPath}
                  height={editorHeight} // By default, it fully fits with its parent
                  language={'sql'}
                  theme={'vs'}
                  // defaultValue={convertCmdState.convertddl.proto}
                  value={convertState.ddl}
                  // onMount={handleEditorDidMount}
                  onChange={(e) => {
                    if (convertState.ddl.trimEnd() !== e.trimEnd()) {
                      dispatch(setDdlAction(e));
                    }
                  }
                  }
                  options={{
                    readOnly: false,
                    scrollbar: {
                      // Subtle shadows to the left & top. Defaults to true.
                      useShadows: false,
                      // Render vertical arrows. Defaults to false.
                      verticalHasArrows: false,
                      // Render horizontal arrows. Defaults to false.
                      horizontalHasArrows: false,
                      // Render vertical scrollbar.
                      // Accepted values: 'auto', 'visible', 'hidden'.
                      // Defaults to 'auto'
                      vertical: 'hidden',
                      // Render horizontal scrollbar.
                      // Accepted values: 'auto', 'visible', 'hidden'.
                      // Defaults to 'auto'
                      horizontal: 'hidden',
                      verticalScrollbarSize: 0,
                      // horizontalScrollbarSize: 17,
                      // arrowSize: 30
                    },
                    lineNumbers: 'on',
                    automaticLayout: true,
                    contextmenu: false,
                    // formatOnPaste: true,
                    minimap: {
                      enabled: false,
                      showSlider: 'always',
                    },
                  }}
                />
              </div>
            </Col>

            <Col span={COL_AB_SPAN.B}>

              <div>
                <Editor
                  theme={'vs'}
                  language={convertState.archetype.lang}
                  height={editorHeight} // By default, it fully fits with its parent
                  value={convertState.result}
                  // onMount={handleEditorDidMount}
                  options={{
                    readOnly: false,
                    scrollbar: {
                      // Subtle shadows to the left & top. Defaults to true.
                      useShadows: false,
                      // Render vertical arrows. Defaults to false.
                      verticalHasArrows: false,
                      // Render horizontal arrows. Defaults to false.
                      horizontalHasArrows: false,
                      // Render vertical scrollbar.
                      // Accepted values: 'auto', 'visible', 'hidden'.
                      // Defaults to 'auto'
                      vertical: 'hidden',
                      // Render horizontal scrollbar.
                      // Accepted values: 'auto', 'visible', 'hidden'.
                      // Defaults to 'auto'
                      horizontal: 'hidden',
                      verticalScrollbarSize: 0,
                      // horizontalScrollbarSize: 17,
                      // arrowSize: 30
                    },
                    minimap: {
                      enabled: true,
                      showSlider: 'always',
                    },

                  }}

                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={1}></Col>
      </Row>
    </>
  );
};
