export const OPTIONS_URL = "http://localhost:8080/options"
export const CONVERT_URL = "http://localhost:8080/convert"


export const DefaultSQL = "create table address_book_0\n" +
    "(\n" +
    "    id            bigint auto_increment comment 'ID'\n" +
    "        primary key,\n" +
    "    address_no    varchar(32)              not null comment '地址唯一编号',\n" +
    "    owner_id      varchar(32)              not null comment '持有者ID',\n" +
    "    name          varchar(64)              not null comment '姓名',\n" +
    "    mobile        varchar(32)              not null comment '手机',\n" +
    "    phone         varchar(32)              null comment '电话',\n" +
    "    is_default    tinyint(1)  default 0    not null comment '0:非默认，1:默认地址',\n" +
    "    owner_type    tinyint(2)  default 0    not null comment '0会员，1商家',\n" +
    "    app_id        varchar(32)              not null comment '租户ID',\n" +
    "    platform      tinyint(2)  default 1    not null comment '平台：1：all，2：ios，3：Android',\n" +
    "    country       varchar(32) default 'CN' not null comment '国家',\n" +
    "    province      varchar(32) default ''   not null comment '省',\n" +
    "    province_code varchar(16) default ''   not null comment '省份编码',\n" +
    "    city          varchar(32) default ''   not null comment '市',\n" +
    "    city_code     varchar(16) default ''   not null comment '城市编码',\n" +
    "    district      varchar(32) default ''   not null comment '区县',\n" +
    "    district_code varchar(16) default ''   not null comment '区县编码',\n" +
    "    town          varchar(32) default ''   not null comment '街镇',\n" +
    "    town_code     varchar(16) default ''   not null comment '街镇编码',\n" +
    "    address       varchar(512)             not null comment '详细地址',\n" +
    "    zip_code      varchar(32)              null comment '邮编',\n" +
    "    create_time   datetime                 not null comment '创建时间',\n" +
    "    update_time   datetime                 null null comment '更新时间',\n" +
    "    ext_info      json                     null comment '扩展字段',\n" +
    "    source        tinyint                  null comment '来源',\n" +
    "    valid         tinyint(1)  default 1    not null comment '1未删除,0已删除',\n" +
    "    update_by     varchar(64)              null comment '更新人',\n" +
    "    create_by     varchar(64)              null comment '创建人'\n" +
    ")\n" +
    "    collate = utf8mb4_unicode_ci;";