import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { convertDdlThunkAction, setArchetype } from './sourceStateReducer';

export interface ArtifactFactory {
  archetypes: Archetype[];
  // dialects: string;
}

export type OptionType = 'INPUT' | 'RADIO' | 'BOOL' | 'SELECT';

export interface Archetype {
  id: number;
  desc: string;
  lang: string;
  options: Option[];
  templates: Template[],
}

export interface Artifact {
  header: string,
  body: string
}

export interface Template {
  key: String,
  header: String,
  body: String,
  desc: string | null
}

export interface Option {
  key: string;
  desc: string;
  defaultVal: string;
  type: OptionType;
  values: Value[] | [] | null;
}

export interface Value {
  value: string;
  desc: string;
}

export const fetchOptionThunkAction = createAsyncThunk<ArtifactFactory, number>(
  'fetchOptions',
  async (userId, thunkApi) => {
    /*
            const response = await fetch(OPTIONS_URL, {
                cache: "force-cache",
                method: "GET"
            })
            return (await response.json()) as (ConvertOptionState);
    */
    let options: ArtifactFactory = {
        archetypes: [
          {
            id: 0, desc: 'first demo', lang: 'text', options: [
              { key: 'key1', desc: 'desc for demo', defaultVal: null, type: 'BOOL', values: [] },
              {
                key: 'key2',
                desc: 'select desc',
                defaultVal: 'SETTER',
                type: 'SELECT',
                values: [{ value: 'GETTERS', desc: 'GETTERS' }, { value: 'SETTER', desc: 'SETTERS' }],
              },
              {
                key: 'key4',
                desc: 'input desc',
                defaultVal: 'SETTER',
                type: 'INPUT',
                values: [{ value: 'GETTERS', desc: 'GETTERS' }, { value: 'SETTER', desc: 'SETTERS' }],
              },
              {
                key: 'key5',
                desc: 'radio desc',
                defaultVal: 'SETTER',
                type: 'RADIO',
                values: [{ value: 'GETTERS', desc: 'GETTERS' }, { value: 'SETTER', desc: 'SETTERS' }],
              },
            ], templates: [
              { key: 'key0', body: 'This is Test ', header: '', desc: '' },

            ],
          },
          {
            id: 1, desc: 'second demo', lang: 'html', options: [
              { key: 'key21', desc: 'desc for demo', defaultVal: null, type: 'BOOL', values: [] },
              {
                key: 'key22',
                desc: 'desc ',
                defaultVal: 'SETTER',
                type: 'SELECT',
                values: [{ value: 'GETTERS', desc: 'GETTERS' }, { value: 'SETTER', desc: 'SETTERS' }],
              },
            ], templates: [
              { key: 'key23', body: 'This is second Test <html><a>xxx</a></html>', header: 'secondHeader', desc: '' },

            ],
          },
        ],
      }
    ;
    return await options;
  });


const initialState = {
  archetypes: [] as Archetype[],
};

const artifactFactorySliceReducer = createSlice({
  name: 'archetypeFactory',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOptionThunkAction.fulfilled, (state, { payload }) => {
      console.log('change state', payload);
      state.archetypes = payload.archetypes;
      console.log('change state after', state.archetypes);
    })
      .addCase(fetchOptionThunkAction.rejected, (state, { payload }) => {
        console.log('rejected ', state);
        console.log(payload);
      });
  },
});

export default artifactFactorySliceReducer.reducer;

